import React from 'react';
import SEO from '../components/SEO';
import Mailto from '../components/Mailto';
import Layout from '../components/Layout/Layout';
import Section from '../components/Section/Section';

const MentionsLegalesPage: React.FC = () => (
  <Layout
    title="Mentions légales"
    crumbs={[
      { name: 'Accueil', path: '/' },
      { name: 'Mentions légales', path: '/mentions-legales/' },
    ]}
  >
    <SEO
      title="Mentions légales | Poupée Odette"
      description="Mentions légales pour poupeeodette.fr"
      keywords={['mentions légales']}
    />

    <Section>
      <p>
        Le site <a href="https://poupeeodette.fr">https://poupeeodette.fr</a>{' '}
        est édité par : POUPÉE ODETTE [Société en cours d'immatriculation]
        <br />
        E-mail :{' '}
        <Mailto address="hello@poupeeodette.fr">hello@poupeeodette.fr</Mailto>
        <br />
        L’hébergement du site internet{' '}
        <a href="https://poupeeodette.fr">https://poupeeodette.fr</a> est assuré
        par Netlify. Adresse : 2325 3rd St Suite 215, San Francisco, CA 94107,
        États-Unis.
        <br />
        Crédits Photos : Poupée Odette. Les photographies utilisées sur ce site
        font l’objet de droits réservés. Elles ne peuvent pas être utilisées ou
        dupliquées sans l’autorisation écrite de Poupée Odette.
        <br />
        Site créé et administré par : Poupée Odette.
      </p>
      <h3>PROPRIÉTÉ INTELLECTUELLE</h3>
      <p>
        Poupée Odette est une marque déposée auprès de l’INPI. L’intégralité du
        site est la propriété exclusive de Poupée Odette. Aucune reproduction ou
        représentation ne peut avoir lieu sans le consentement écrit et
        préalable de Poupée Odette.
      </p>
      <p>
        Tous les éléments du site, notamment tous les éléments graphiques,
        sonores, textuels sont la propriété exclusive de Poupée Odette.
      </p>
      <p>
        L’ensemble de ce site relève de la législation française et
        internationale sur le droit d’auteur et la propriété intellectuelle.
        Tous les droits de reproduction sont réservés, y compris les
        représentations iconographiques et photographiques.
      </p>
      <h3>RESPONSABILITÉ DE POUPÉE ODETTE</h3>
      <p>
        Les informations communiquées sur le site sont fournies à titre
        indicatif. Elles ne sauraient engager la responsabilité de Poupée
        Odette. Elles peuvent être modifiées ou mises à jour sans préavis.
      </p>
      <p>
        La responsabilité de Poupée Odette ne saurait être engagée pour tout
        dommage, de quelque nature qu’il soit, direct ou indirect, toute
        omission ou erreur ou impossibilité d’accéder au site.
      </p>
      <h3>UTILISATION DES « COOKIES »</h3>
      <p>
        Nous utilisons des cookies pour vous proposer un site internet facile
        d’utilisation, sécurisé et fonctionnel.
      </p>
      <p>
        Un cookie est un fichier texte stocké par un site Internet sur un
        appareil (ordinateur, tablette ou mobile) afin de recueillir des données
        concernant votre navigation sur ce site. Nous utilisons des cookies afin
        d’identifier les rubriques et les pages que vous consultez dans le but
        de faciliter votre navigation et votre expérience utilisateur.
      </p>
      <p>
        Instagram et Google sont susceptibles de collecter ou de recevoir des
        informations provenant de notre site web, et d’utiliser ces informations
        pour fournir des services de mesure et des publicités ciblées. Vous
        pouvez refuser la collecte et l’utilisation des informations destinées
        au ciblage publicitaire, en n’approuvant pas les cookies lors de votre
        première visite sur le site.
      </p>
    </Section>
  </Layout>
);

export default MentionsLegalesPage;
